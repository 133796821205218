import React from 'react'

import { card } from '../assets/index.js'

import styles , {layout} from '../style'

import {Button} from './index'

const CardDeal = () => {
  return (
    <section layout={layout.section} >
      <div className={layout.sectionInfo} >
      <h2 className={styles.heading2}>
        Find a better card deal <br className="sm:block hidden" /> in few easy
        steps.
      </h2>
      <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
      Arcu tortor, purus in mattis at sed integer faucibus. Aliquet quis
        aliquet eget mauris tortor.ç Aliquet ultrices ac, ametau.
      </p>
      <Button styles={`mt-10`} />
      </div>
      <div className={layout.sectionImg} >
        <img src={card} alt='billing' className='w-[100%] h-[100%]' />
      </div>
    </section>
  )
}

export default CardDeal